/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it
const { navigate } = require("gatsby-plugin-intl")

exports.onInitialClientRender = () => {
  const constantMock = window.fetch
  window.fetch = function (...args) {
    return new Promise((resolve, reject) => {
      constantMock
        .apply(this, args)
        .then((response) => {
          if (response.status !== 200)
            navigate("/not-found", { state: { errorCode: response.status } })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
exports.onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}
